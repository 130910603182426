import React, { useState } from 'react'

import { Form, Button, Input, Space, Flex } from "antd";
import { ReactComponent as LogoIcon } from "../assets/logo.svg";
import { useNavigate, useParams, useLocation, Navigate } from 'react-router-dom';
import { message } from 'antd';
import { base_URL } from '../components/api/api';
import axios from 'axios';

const ValidateToken = () => {
    const params=useParams();
    const navigate=useNavigate();
    console.log(params.id);
    const token=
        {activationkey: params.id}
    ;
    console.log(token)
    const validateActivationKey=async()=>{
        try{
            const response = await axios.post(`${base_URL}/user/validateforgottoken`, token);
           // console.log(response)
            console.log("token validated:", response);
            message.success("User is validated!!");
            navigate(`/resetpassword/${params.id}`)
        }
        catch(error){
            console.error("Error submitting data:", error);
            message.error("Link expired!")

            
        }
    };
    validateActivationKey();

}

export default ValidateToken;