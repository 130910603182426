import React, { useState, useEffect } from 'react';
import { Tree, Button, message, Modal, Select, Input, Space, Popconfirm, Card, Tabs } from 'antd';
import { PlusOutlined, MinusOutlined, EditOutlined, DownOutlined } from '@ant-design/icons';
import { getRequest, postRequest } from "../../../components/api/api";
import { useSelector } from "react-redux";
import {  useLocation } from "react-router-dom";

import { debounce } from 'lodash';
export const OrgSetup = () => {
  const tenantId = useSelector((state) => state.tenant.tenantId);
  const tenantSubsidiaryId = useSelector((state) => state.tenant.tenantSubsidiaryId);
  const userId = useSelector((state) => state.auth.userId);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [newTitle, setNewTitle] = useState('');
  const [data, setData] = useState([]);
  const location = useLocation();


  useEffect(() => {
    if (tenantId && tenantSubsidiaryId) {
      loadDefaultLevelData();
      loadData();
    }
  }, [tenantId, tenantSubsidiaryId]);

  const loadData = async () => {
    try {
      setIsLoadingData(true);
      const response = await getRequest(`organization-levels/getorglevel/${tenantId}/${tenantSubsidiaryId}`);
      if (response && response.length > 0) {
        setTreeData(buildHierarchy(response));
      }
    } catch (error) {
      message.error("Error loading data.");
    } finally {
      setIsLoadingData(false);
    }
  };

  const loadDefaultLevelData = async () => {
    try {
      //setIsLoadingData(true);
      const response = await getRequest(`organization-levels/getorgdefaultlevel/${tenantId}/${tenantSubsidiaryId}`);
      if (response && response.length > 0) {
        setData(response);
      }
    } catch (error) {
      message.error("Error loading data.");
    } finally {
      //setIsLoadingData(false);
    }
  };

  const buildHierarchy = (data) => {
    const map = new Map();
    data.forEach(item => {
      map.set(item.key, { ...item, children: [] });
    });
    const hierarchy = [];
    data.forEach(item => {
      if (item.parentId) {
        const parent = map.get(item.parentId);
        if (parent) {
          parent.children.push(map.get(item.key));
        }
      } else {
        hierarchy.push(map.get(item.key));
      }
    });
    return hierarchy;
  };
  const handleNameChange = (value, index) => {
    const updatedData = [...data];
    updatedData[index].name = value;
    setData(updatedData);
  };

  const handleSave = async () => {
    try {
      let payload = { data: data, createdBy: userId }
      const response = await postRequest('/organization-levels/editDefaultOrgLevel', payload);
      if (response && response[0].result) {
        message.success('Data updated successfully!');
      } else {
        message.error('Failed to update data!');
      }
    } catch (error) {
      message.error('Error while saving data');
    }
  };

  const showModal = (node) => {
    setSelectedNode(node);
    setSelectedLevel(node.level || null);
    setNewTitle(node.title); // Set the current title as the new title
    setIsModalVisible(true);
  };

  const handleLevelChange = (value) => {
    setSelectedLevel(value);
    let data = { ...selectedNode };
    data.levelId = value;
    setSelectedNode(data);
  };

  const handleTitleChange = (e) => {
    setNewTitle(e.target.value);
  };

  const handleOk = async () => {
    if (selectedNode) {
      let response = await renameNode(selectedNode.key, selectedNode.levelId, selectedNode.parentId, newTitle);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const debouncedRenameNode = debounce(async (key, levelId, parentId, newName) => {
    let payload = {
      parentId,
      tenantId,
      tenantSubsidiaryId,
      name: newName,
      description: newName,
      createdBy: userId,
      id: key,
      levelId: levelId,
    };
    const response = await postRequest('/organization-levels/addedit', payload);
    if (response) {
      if (response[0].result) {
        loadData(parentId);
        message.success(response[0].message);
        setIsModalVisible(false);
      } else {
        message.error(response[0].message);
      }
    }
  }, 500);

  const renameNode = (key, levelId, parentId, newName) => {
    debouncedRenameNode(key, levelId, parentId, newName);
  };

  const addNode = async (key, parentId) => {
    let payload = {
      parentId,
      tenantId,
      tenantSubsidiaryId,
      name: 'New Node',
      description: 'New Node',
      createdBy: userId,
    };
    const response = await postRequest('/organization-levels/addedit', payload);
    if (response) {
      if (response[0].result) {
        loadData(parentId);
      }
      else {
        message.error(response[0].message);
      }
    }
  };

  const removeNode = async (key) => {
    let payload = {

      id: key,

    };
    const response = await postRequest('/organization-levels/removeNode', payload);
    if (response) {
      if (response[0].result) {
        loadData(null);
        message.success(response[0].message);
        setIsModalVisible(false);
      } else {
        message.error(response[0].message);
      }
    }


    // const newData = [...treeData];
    // const removeNodeRecursively = (data, key) => {
    //   for (let i = 0; i < data.length; i++) {
    //     if (data[i].key === key) {
    //       data.splice(i, 1);
    //       return true;
    //     } else if (data[i].children) {
    //       if (removeNodeRecursively(data[i].children, key)) {
    //         return true;
    //       }
    //     }
    //   }
    //   return false;
    // };
    // removeNodeRecursively(newData, key);
    // setTreeData(newData);
    // message.success('Node removed successfully');
  };
  const handleTabClick = (key) => {
    if (key === "2") {
      loadData();
    }
    if (key === "1") {
      loadDefaultLevelData();
    }
  };

  return (
    <div className="m-5 font-sans w-full max-w-7xl bg-white rounded-xl shadow-md p-6">
      <Tabs defaultActiveKey="1" type="card" onTabClick={handleTabClick}>
        <Tabs.TabPane tab="Organizational Levels" key="1">
          <div className="p-4">
            <Space direction="vertical" className="w-full"> 
              {data.map((item, index) => (
              <div key={item.id} className="mb-2 flex flex-wrap items-center  p-1 space-x-2 sm:space-x-4 w-full shadow-md sm:shadow-none">
              <Card
                title={`Level ${item.sequenceNo}`}
                size="small"
                className="w-full sm:w-64 md:w-1/4 lg:w-1/5 h-[2rem] mr-1 custom-card-color"
                style={{ backgroundColor: item.colorTheme }}
              ></Card>
              <Input
                value={item.name}
                onChange={(e) => handleNameChange(e.target.value, index)}
                placeholder="Enter level name"
                className=" md:block hidden m-2 md:w-full w-[94%] "
                style={{flex:1}}
                
              />

<Input
                value={item.name}
                onChange={(e) => handleNameChange(e.target.value, index)}
                placeholder="Enter level name"
                className=" md:hidden block m-2 md:w-full w-[94%] "
                
                
              />
            </div>
))}
              <div className="flex justify-end">
                <Button
                  type="primary"
                  onClick={handleSave}
                  style={{ width: '150px' }} // Set fixed width for the button
                >
                  Save Changes
                </Button>
              </div>
            </Space>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Organization Setup" key="2">
          <Tree
            className="bg-gray-50 p-2 rounded-lg"
            blockNode
            showLine
            switcherIcon={<DownOutlined />}
            
            defaultExpandAll
            treeData={treeData}
            selectedKeys={selectedNode ? [selectedNode.key] : []}
            titleRender={(nodeData) => (
              <div className="flex flex-wrap justify-between items-center mb-2   rounded-xl   hover:bg-gray-100">
                <Space>
                  <Card
                    title={nodeData.level}
                    size="small"  
                    className="custom-card-color"
                    style={{ width: '150px', 
                      height: 35, 
                      backgroundColor: nodeData.colorTheme,
                      
                     }}
                  ></Card>
                  <Card
                    title={nodeData.title}
                    size="small"
                    className="w-full sm:w-64 md:w-80"
                    style={{ backgroundColor: '#EAEFF5', height: '40px', fontWeight: 'bolder', padding: '1px' }}
                  >

                  </Card>
                </Space>
                <div className="flex items-center mt-2 sm:mt-0  gap-1">
                  {/* <Button
                    icon={<PlusOutlined />}
                    size="small"
                    type="primary"
                    className="ml-2"
                    onClick={() => addNode(nodeData.key, nodeData.key)}
                  /> */} <PlusOutlined
                    title="view"
                    className="text-[#0085A4] p-1.5 rounded-full border-2 border-grey-600 w-1/3 "
                    onClick={() => addNode(nodeData.key, nodeData.key)}
                  />
                  {/* <Button
                    icon={<EditOutlined />}
                    size="small"
                    type="primary"
                    className="ml-2"
                    onClick={() => showModal(nodeData)}
                  /> */}
                  <EditOutlined
                    title="view"
                    className="text-[#0085A4] p-1.5 rounded-full border-2 border-grey-600 w-1/3 "
                    onClick={() => showModal(nodeData)}
                  />
                  <Popconfirm
                    title="Are you sure you want to delete this node?"
                    onConfirm={() => removeNode(nodeData.key)}
                    okText="Yes"
                    cancelText="No"
                  >
                    {/* <Button
                      icon={<MinusOutlined />}
                      size="small"
                      type="primary"
                      className="ml-2"
                    /> */}
                    <MinusOutlined
                      title="view"
                      className="text-[#0085A4] p-1.5 rounded-full border-2 border-grey-600 w-1/3 "

                    />
                  </Popconfirm>

                </div>
              </div>
            )}
          />
        </Tabs.TabPane>
      </Tabs>
      <Modal
        title="Edit Node"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width="40%"
      >
        <Space direction="vertical" className="w-full">
          <Input
            value={newTitle}
            onChange={handleTitleChange}
            placeholder="Edit title"
          />
        </Space>
      </Modal>
    </div>
  );
};
