import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import axios from "axios";
import { message } from "antd";
import cookies from "react-cookies";

import { AppUserType, userTypeMap } from "../constants/Constant";

import { isLogin } from "../store/slices/auth-slice";
import {
  setLogoUrl,
  setSubsidiaryName,
  setTenantId,
  setTenantSubsidiaryId,
  setUserType,
  setPermissions,
  setTenantName,
  setRoleName,
  setRoleTypeId,
} from "../store/slices/tenant-slice";
import {
  setIdToken,
  setUserId,
  setUserName,
  setName,
  setCode,
} from "../store/slices/auth-slice";

import { base_URL } from "../components/api/api";
import { validateLogin, changePassword } from "../components/validator";

const useCheckAuth = (idToken, dispatch, action) => {
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Domain validation
        dispatch(setLogoUrl(localStorage.getItem("logoUrl")));
        dispatch(
          setSubsidiaryName(localStorage.getItem("tenantSubsidiaryName"))
        );
        dispatch(setTenantId(localStorage.getItem("tenantId")));
        dispatch(
          setTenantSubsidiaryId(localStorage.getItem("tenantSubsidiaryId"))
        );
        dispatch(setTenantId(localStorage.getItem("tenantId")));

        if (idToken) {
          dispatch(action());

          // Login
          dispatch(setIdToken(localStorage.getItem("token")));
          dispatch(setRoleName(localStorage.getItem("roleName")));
          dispatch(setRoleTypeId(localStorage.getItem("roleTypeId")));
          dispatch(setUserId(localStorage.getItem("userId")));
          dispatch(setUserName(localStorage.getItem("userName")));
          dispatch(setCode(localStorage.getItem("code")));
          dispatch(setName(localStorage.getItem("name")));
          dispatch(setTenantName(localStorage.getItem("tenantName")));
          dispatch(setRoleName(localStorage.getItem("roleName")));
          dispatch(setRoleTypeId(localStorage.getItem("setRoleTypeId")));
          dispatch(
            setPermissions(JSON.parse(localStorage.getItem("permissions")))
          );
          dispatch(setUserType(localStorage.getItem("userType")));
        }
      } catch (error) {
        console.error("Authentication error:", error);
      }
    };

    fetchData();
  }, [idToken, dispatch, action]);
};

export default useCheckAuth;

export const useLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = async () => {
    const validationMessage = validateLogin(username, password);

    if (validationMessage !== true) {
      message.error(validationMessage, 2);
      return;
    } else {
      try {
        setIsLoading(true);
        const response = await axios.post(`${base_URL}/user/login`, {
          username: username,
          password: password,
          usertype: "1",
          //isFirstLogin: true,
          tenantId: "", //localStorage.getItem("tenantId"),
          tenantSubsidiaryId: "",
        });
        console.log("login response", response);
        if (response.data.result) {
          setIsLoading(false);
          dispatch(isLogin());

          cookies.save("username", username, {
            path: "/",
            maxAge: 604800,
            secure: true,
            sameSite: "strict",
          });

          localStorage.setItem("token", response.data.token);
          localStorage.setItem("userId", response.data.userId);
          localStorage.setItem("userName", response.data.userName);
          localStorage.setItem("name", response.data.name);
          localStorage.setItem("tenantName", response.data.tenantName);
          localStorage.setItem("code", response.data.code);
          localStorage.setItem("logoUrl", response.data.logoUrl);
          localStorage.setItem(
            "tenantSubsidiaryName",
            response.data.tenantSubsidiaryName
          );
          localStorage.setItem("tenantId", response.data.tenantId);
          localStorage.setItem("roleName", response.data.roleName);
          localStorage.setItem("roleTypeId", response.data.roleTypeId);
          localStorage.setItem(
            "tenantSubsidiaryId",
            response.data.tenantSubsidiaryId
          );
          localStorage.setItem("userType", userTypeMap[response.data.userType]);

          dispatch(setLogoUrl(response.data.logoUrl));
          dispatch(setSubsidiaryName(response.data.tenantSubsidiaryName));
          dispatch(setTenantName(response.data.tenantName));
          dispatch(setTenantSubsidiaryId(response.data.tenantSubsidiaryId));
          dispatch(setTenantId(response.data.tenantId));
          dispatch(setRoleName(response.data.roleName));
          dispatch(setRoleTypeId(response.data.roleTypeId));
          dispatch(setIdToken(response.data.token));
          dispatch(setUserId(response.data.userId));
          dispatch(setUserName(response.data.userName));
          dispatch(setName(response.data.name));
          dispatch(setCode(response.data.code));
          dispatch(setUserType(userTypeMap[response.data.userType]));
          // message.success(response.data.message, 2);

          setUsername("");
          setPassword("");

          if (response.data.isFirstLogin) {
            navigate(`/resetpassword/${response.data.forgotKey}`);
          } else navigate("/dashboard");
        } else {
          message.error(response.data.message, 2);
          setPassword("");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Login error: ", error);
        message.error(
          error.response.data.message || "An error occurred during login",
          2
        );
        setIsLoading(false);
      }
    }
  };

  return {
    username,
    setUsername,
    password,
    setPassword,
    isLoading,
    handleLogin,
  };
};

export const useValidateDomain = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const hostname = window.location.hostname;
  let subdomain;

  if (hostname === "localhost") {
    subdomain = "admin";
  } else {
    const parts = hostname.split(".");
    subdomain = parts.length > 2 ? parts[0] : null;
  }

  useEffect(() => {
    const validate = async () => {
      try {
        setLoading(true);
        const validateRequest = await axios.get(
          `${base_URL}/validateDomain/${subdomain}`
        );
        setData(validateRequest.data);

        localStorage.setItem("logoUrl", validateRequest.data[0].logoUrl);
        localStorage.setItem(
          "subsidiaryName",
          validateRequest.data[0].subsidiaryName
        );
        localStorage.setItem("tenantId", validateRequest.data[0].tenantId);
        localStorage.setItem(
          "tenantSubsidiaryId",
          validateRequest.data[0].tenantSubsidiaryId
        );
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    validate();
  }, [subdomain]);

  return { data, loading, error };
};
