import { useDispatch, useSelector } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { ConfigProvider } from "antd";

import { router, tenantRouter } from "./routes/router";
import { isLogin } from "./store/slices/auth-slice";
import useCheckAuth from "./hooks/useAuth";

import "./App.css";

const App = () => {
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);
  const idToken = localStorage.getItem("token");
  // console.log("idToken", idToken);

  useCheckAuth(idToken, dispatch, isLogin);

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorBgContainer: isDarkMode === "dark" ? "#FFFFFF" : "#0085A4",
            colorText: isDarkMode === "dark" ? "#000000" : "#FFFFFF",
            colorBgHover: isDarkMode === "dark" ? "#1D1D1D" : "#000000",
          },
          Dropdown: {
            colorText: "#000000",
          },

          Input: {
            colorBgContainer: isDarkMode === "dark" ? "#FFFFFF" : "#FFFFFF",
            colorText: isDarkMode === "dark" ? "#FFFFFF" : "#000000",
          },
        },
        token: {
          colorPrimary: isDarkMode === "dark" ? "" : "#0085A4",
          colorBgContainer: isDarkMode === "dark" ? "#333333" : "#FFFFFF",
          colorText: isDarkMode === "dark" ? "#FFFFFF" : "#3C4A60D9",
        },
      }}
    >
      <div className={isDarkMode === "dark" ? "dark-mode" : "light-mode"}>
        <RouterProvider router={tenantRouter} />
      </div>
    </ConfigProvider>
  );
};

export default App;
