import { useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { Suspense } from "react";
import { Spin } from "antd";

import SideTopbar from "../components/SideTopbar";

const RootLayout = () => {
  useEffect(() => {
    const disableBackButton = () => {
      window.history.pushState(null, " ", window.location.href);

      window.onpopstate = function (event) {
        window.history.go(1);
      };
    };

    disableBackButton();

    return () => {
      window.onpopstate = null;
    };
  }, []);

  return (
    <SideTopbar>
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spin tip="Loading..." size="large" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </SideTopbar>
  );
};

export default RootLayout;
