import { configureStore } from "@reduxjs/toolkit";

import themeReducer from "./slices/theme-slice";
import authReducer from "./slices/auth-slice";
import tenantSlice from "./slices/tenant-slice";

const store = configureStore({
  reducer: {
    theme: themeReducer,
    auth: authReducer,
    tenant: tenantSlice,
  },
});

export default store;
